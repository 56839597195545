export const actualDepthArr = (operation) => {
  const depthsArr = operation.activities.reduce((acc, activity, idx) => {
    if (idx === 0) {
      acc.push('');
    } else if (
      idx > 0 &&
      activity.plannedDepth === operation.activities[idx - 1].plannedDepth &&
      activity.actual &&
      !activity.actualDepth
    ) {
      if (operation.activities[idx - 1].actualDepth) {
        acc.push({
          value: operation.activities[idx - 1].actualDepth,
          id: operation.activities[idx]?.timeTrackerItemId,
          parentId: operation.timeTrackerItemId,
        });
      } else {
        acc.push({
          value: acc[idx - 1]?.value ?? '',
          id: operation.activities[idx]?.timeTrackerItemId,
          parentId: operation.timeTrackerItemId,
        });
      }
    } else {
      acc.push({
        value: '',
      });
    }
    return acc;
  }, []);
  return depthsArr;
};
